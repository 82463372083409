<template>
  <div class="page-container">
    <headerTab activeIndex="4"></headerTab>
    <div class="p-t-header">
      <div class="p-t-inner">
        <img
          class="au-title"
          v-lazy="require('../../assets/images/title21.png')"
        />
        <div class="au-bg-dt" align="center">
          <span class="au-head-line"></span>
          <div class="au-head-d1">
            <span
              >吉林省伟诚大药房成立于2019年1月，地址位于长春市朝阳区工农大路富苑盛世城二期，<br
            /></span>
            <span
              >紧邻吉林省人民医院，本药店发展多年来，是零售（连锁）企业，主要经营药品、中药饮片、中成药、化学药、医疗器械………等等，<br
            /></span>
            <span
              >可以满足各大人民群众日以需求，愿与社会各界同仁携手合作，谋求共同发展，继续为新老客户提供的产品和服务，我店铺与多家医药公司建立了长期稳定的合作关系，
              <br
            /></span>
            <span
              >重信用、守合同、保证产品质量，赢得了广大客户的信任，店铺，联系电话为189-5499-6772，期待您的来电咨询<br
            /></span>
            <!-- <span>具备专业的服务能力及良好售后服务保障能力，确保消费者线上线下都能获得满意的购物体验。<br></span> -->
          </div>

          <!-- <div class="au-head-d2">
            <img
              style="margin-top: -0.2rem;margin-right: 0.3rem"
              v-lazy="require('../../assets/images/fh001.png')"
            />
            <span>打造基于大数据的生态闭环，推动医疗+医药行业的发展升级</span>
            <img
              style="margin-top: 0.6rem;margin-left: 0.3rem"
              v-lazy="require('../../assets/images/fh002.png')"
            />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerTab from "@/components/headerTab";
export default {
  name: "us",
  components: {
    headerTab,
  },
  data() {
    return {
      name: "九药云",
    };
  },
};
</script>

<style lang="less" scoped>
.p-t-header {
  position: relative;
  width: 100%;
  height: 16rem;
}
.p-t-inner {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.au-title {
  width: 6rem;
}
.au-bg-dt {
  height: 9.4rem;
  border-top: 1px solid transparent;
  background-image: url("../../assets/images/lunbo4.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.au-head-line {
  display: block;
  width: 1rem;
  height: 0.1rem;
  background: #6aa5ff;
  margin-top: 1.1rem;
}
.au-head-d1 {
  margin-top: 1.1rem;
  text-align: center;
  span {
    display: block;
    font-size: 0.4rem;
    color: #4a4a4a;
    line-height: 2.1;
  }
}
.au-head-d2 {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
  img {
    width: 0.5rem;
    height: 0.38rem;
  }
  span {
    font-size: 0.65rem;
    font-weight: 600;
  }
}
</style>
